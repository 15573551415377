@font-face {
  font-family: "proxima-nova";
  src: url("/fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("/fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: proxima-nova, sans-serif;
}

.type_title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
}

@media (prefers-color-scheme: dark) {
  #root,
  body,
  html {
    background-color: rgba(55, 56, 58, 1);
  }

  .type_title {
    color: #e8eaed;
  }
}

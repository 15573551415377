.carousel {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.carousel img {
  width: 60% !important;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
}

.carousel__button.show {
  opacity: 1;
}

.carousel__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.carousel__button--left {
  left: 48px;
}

.carousel__button--right {
  right: 48px;
}

.images {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
}

.carousel__image {
  border-radius: 6px;
}

.carousel__image--current {
  width: 500px;
  height: 210px;
  background-size: cover;
  cursor: pointer;
}

.carousel__image--prev {
  width: calc((100% - 500px) / 2);
  height: 189px;
  background-size: cover;
  border-radius: 6px 0 0 6px;
}

.carousel__image--next {
  width: calc((100% - 500px) / 2);
  height: 189px;
  background-size: cover;
  background-position-x: 100%;
  border-radius: 0 6px 6px 0;
}

.carousel .slide {
  background: transparent;
  margin-bottom: 24px;
}

.slide div {
  background-color: transparent;
}

.carousel .control-dots {
  padding: 0;
  bottom: -6px !important;
}

.carousel .control-dots .dot {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  width: 4px;
  height: 4px;
  margin: 0 4px;
}

@media (prefers-color-scheme: dark) {
  .carousel .control-dots .dot {
    filter: invert(100%);
  }
}

:global(.MuiModal-root) {
  overflow-y: scroll !important;
}

.carousel .arrow {
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1300;
}

.carousel .arrow.hide {
  opacity: 0;
}

.carousel .arrow.left {
  left: 10%;
}

.carousel .arrow.right {
  right: 10%;
}

.carousel .arrow.android.left {
  left: 8%;
}

.carousel .arrow.android.right {
  right: 8%;
}
